import * as React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const Footer = () => (
  <StyledFooter>
    <Link to="/">
      <StaticImage
        src="../images/logo_educa_tu_region.png"
        alt="Logo educa tu región"
        placeholder="blurred"
        width={120}
        quality={100}
      />
    </Link>
    <div>
      © {new Date().getFullYear()}, Página por
      {` `}
      <a href="https://www.github.com/ferbaco86" rel="noreferrer">
        ferbaco86
      </a>
    </div>
    <SocialContainer>
      <a
        href="https://www.facebook.com/educaantofagasta/"
        target="_blank"
        rel="noreferrer"
      >
        <StaticImage
          src="../images/f_logo_Blue_512.png"
          alt="Logo facebook"
          placeholder="blurred"
          width={60}
          quality={100}
        />
      </a>
      <a
        href="https://www.instagram.com/educaturegion/"
        target="_blank"
        rel="noreferrer"
      >
        <StaticImage
          src="../images/Instagram_logo.png"
          alt="Logo Instagram"
          placeholder="blurred"
          width={60}
          quality={100}
        />
      </a>
    </SocialContainer>
  </StyledFooter>
)
const StyledFooter = styled.footer`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  padding: 0 6rem;
  @media screen and (max-width: 670px) {
    padding: 0;
    flex-direction: column;
  }
`
const SocialContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 140px;
  @media screen and (max-width: 670px) {
    margin-top: 30px;
  }
`

export default Footer
