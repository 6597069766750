import React, { useEffect, useMemo, useState } from "react"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import styled, { css } from "styled-components"
import { throttle } from "lodash"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import MobileMenu from "./mobileMenu"
import text from "../constants/labels.json"

const ScrollBaseline = 100

const Header = () => {
  const [isFixed, setIsFixed] = useState(false)

  const onScrollWindow = useMemo(
    () =>
      throttle(() => {
        setIsFixed(window.scrollY > ScrollBaseline)
      }, 100),
    []
  )

  useEffect(() => {
    onScrollWindow()
    window.addEventListener("scroll", onScrollWindow)

    return () => {
      window.removeEventListener("scroll", onScrollWindow)
    }
  }, [onScrollWindow])

  return (
    <>
      <MobileMenu />
      <NavContainer isFixed={isFixed}>
        <Link to="/">
          <StaticImage
            src="../images/logoEduca2.png"
            alt="Logo educa tu región"
            placeholder="blurred"
            width={255}
            quality={100}
          />
        </Link>
        <NavLinksContainer>
          <NavLinkItem>
            <NavLink to="/#sobre" title="Sobre">
              {text.labels.pages.about}
            </NavLink>
          </NavLinkItem>
          <NavLinkItem>
            <NavLink to="/#servicios" title="Servicios">
              {text.labels.pages.ourServices}
            </NavLink>
          </NavLinkItem>
          <NavLinkItem>
            <NavLink to="/#salidas" title="Salidas">
              {text.labels.pages.trips}
            </NavLink>
          </NavLinkItem>
          <NavLinkItem>
            <NavLink to="/#padres" title="Padres">
              {text.labels.pages.parents}
            </NavLink>
          </NavLinkItem>{" "}
          <NavLinkItem>
            <NavLink to="/#contacto" title="Contacto">
              {text.labels.pages.contact}
            </NavLink>
          </NavLinkItem>
        </NavLinksContainer>
      </NavContainer>
    </>
  )
}

const NavContainer = styled.nav`
  display: flex;
  justify-content: space-between;
  background-color: #e9e5e5;
  align-items: center;
  margin-bottom: 150px;
  font-size: 1.1rem;
  -webkit-box-shadow: 0px 5px 5px 1px rgba(0, 0, 0, 0.36);
  box-shadow: 0px 5px 5px 1px rgba(0, 0, 0, 0.36);

  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  padding: 0.5rem 0.5rem;
  border-bottom: 1px solid transparent;
  transition: 0.25s all ease;
  will-change: padding, background, backdrop-filter;
  ${props =>
    props.isFixed &&
    css`
      background-color: #e9e5e590;
      padding: 0;
      backdrop-filter: blur(10px);
    `}
  @media screen and (max-width: 768px) {
    display: none;
  }
`

const NavLinksContainer = styled.ul`
  display: flex;
  flex: 0;
  width: 100%;
`

const NavLinkItem = styled.li`
  margin-right: 50px;
  list-style-type: none;
`
const NavLink = styled(AnchorLink)`
  text-decoration: none;
  color: #294290;
  &:hover {
    color: #b70f6d;
  }
`

export default Header
