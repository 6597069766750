import React, { useState } from "react"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"

import text from "../constants/labels.json"

const MobileMenu = () => {
  const [menu, showMenu] = useState(false)

  return (
    <>
      <MobileMenuIcon menu={menu} onClick={() => showMenu(!menu)}>
        <div></div>
        <div></div>
        <div></div>
      </MobileMenuIcon>
      <MenuLinks menu={menu}>
        <ul>
        <Link to="/">
          <StaticImage
            src="../images/logo_educa_tu_region.png"
            alt="Logo educa tu región"
            placeholder="blurred"
            width={155}
            quality={100}
          />
        </Link>
          <li>
            <NavLink to="/#sobre" title="Sobre">
              {text.labels.pages.about}
            </NavLink>
          </li>
          <li>
            <NavLink to="/#servicios" title="Servicios">
              {text.labels.pages.ourServices}
            </NavLink>
          </li>
          <li>
            <NavLink to="/#salidas" title="Salidas">
              {text.labels.pages.trips}
            </NavLink>
          </li>
          <li>
            <NavLink to="/#padres" title="Padres">
              {text.labels.pages.parents}
            </NavLink>
          </li>
          <li>
            <NavLink to="/#contacto" title="Contacto">
              {text.labels.pages.contact}
            </NavLink>
          </li>
        </ul>
      </MenuLinks>
    </>
  )
}

const MobileMenuIcon = styled.button`
{
  display: none;

  @media screen and (max-width: 768px) {
    position: fixed;
    top: 2rem;
    right: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 1.5rem;
    background: transparent;
    border: none;
    cursor: pointer;
    z-index: 2;

    div {
      width: 1.5rem;
      height: 0.2rem;
      background: black;
      border-radius: 5px;
      transform-origin: 1px;
      position: relative;
      transition: opacity 500ms, transform 300ms;

      :first-child {
        transform: ${({ menu }) => (menu ? "rotate(45deg)" : "rotate(0)")};
      }

      :nth-child(2) {
        opacity: ${({ menu }) => (menu ? "0" : "1")};
      }

      :nth-child(3) {
        transform: ${({ menu }) => (menu ? "rotate(-45deg)" : "rotate(0)")};
      }
    }
  }
}
`
const MenuLinks = styled.nav`
{
  display: none;
  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    height: 100vh;
    background: #e9e5e590;
    backdrop-filter: blur(10px);
    position: fixed;
    z-index: 1;
    top: 0;
    right: 0;
    width: 30%;
    transition: transform 500ms;
    transform: ${({ menu }) => (menu ? "translateX(0)" : "translateX(100%)")};

    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
    }

    li {
      margin-top: 3rem;
    }
  }
}
`

const NavLink = styled(AnchorLink)`
  @media screen and (max-width: 768px) {
    text-decoration: none;
    color: #294290;
    font-size: 1.5rem;
    font-weight: bold;
    transition: color 300ms;
    &:hover {
      color: #b70f6d;
    }
  }
`

export default MobileMenu
